<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 20 : 2"
      :class="{ 'on-hover': hover }"
    >
      <v-card-title class="subheading font-weight-bold">
        {{ user.firstName }} {{ user.lastName }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list dense color="transparent">
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              E-Mail:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              {{ user.email }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              Registration Date:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              {{ user.registrationDate | formatDate }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              Last Modified:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              {{ user.lastModified | formatDate }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              Last Login:
            </v-list-item-content>
            <v-list-item-content
              class="align-end"
              :class="{ 'sort-by-style': sortBy === 'Formula' }"
            >
              {{ user.lastLogin | formatDate }}
            </v-list-item-content>
          </v-list-item>
          <v-chip
            class="ma-2"
            :class="[user.emailConfirmed ? 'success' : 'error']"
          >
            {{
              user.emailConfirmed
                ? 'Confirmed e-mail'
                : 'Unconfirmed e-mail'
            }}
          </v-chip>
          <v-chip
            class="ma-2"
            :class="[user.activated ? 'success' : 'error']"
          >
            {{ user.activated ? 'Activated' : 'Not Activated' }}
          </v-chip>
          <v-chip v-if="user.admin" class="ma-2" color="info">
            Admin
          </v-chip>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <UserPopup
          :inputUser="user"
          :show="hover"
          :iconColor="iconColor"
          @updateUser="updateUser"
        />
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              v-if="!readonly"
              small
              depressed
              text
              color="transparent"
              @click="deleteUser"
            >
              <v-icon :color="hover ? iconColor : 'transparent'">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span> Delete </span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import UserPopup from './UserPopup';

export default {
  name: 'UserCard',
  props: {
    user: { type: Object, required: true },
    sortBy: { type: String },
    readonly: { type: Boolean, default: false },
    iconColor: { type: String, default: 'white' },
  },
  components: { UserPopup },
  data() {
    return {};
  },
  methods: {
    deleteUser() {
      this.$emit('deleteUser', this.user.publicId);
    },
    activateUser() {
      this.$emit('activateUser', this.user);
    },
    promoteUserToAdmin() {
      this.$emit('promoteUserToAdmin', this.user.publicId);
    },
    updateUser(updatedUser) {
      this.$emit('updateUser', updatedUser);
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.v-card {
  transition: opacity 0.3s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}

.sort-by-style {
  color: yellowgreen;
}
</style>
