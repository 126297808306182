<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          small
          v-on="on"
          :class="buttonClass"
          color="transparent"
          @click="openDialog"
          depressed
          text
        >
          <v-icon :color="show ? iconColor : 'transparent'">
            {{ formIcon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ formTitle }}</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="reset" v-on="on">
                <v-icon> mdi-backup-restore </v-icon>
              </v-btn>
            </template>
            <span> Reset </span>
          </v-tooltip>
          <v-toolbar-title> {{ formTitle }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="updateUser"
                v-on="on"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> Submit </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <UserAuthForm
            v-if="user"
            ref="userAuthForm"
            :type="user.publicId === -1 ? 'Create' : 'Edit'"
            v-model="user"
            @submitForm="updateUser"
          />
          <v-switch
            v-model="resetCurrentProject"
            label="Reset Current Project"
          ></v-switch>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserAuthForm from '@/components/auth/UserAuthForm';

export default {
  name: 'UserPopup',
  props: {
    inputUser: { type: Object, required: true },
    show: { type: Boolean, default: true },
    iconColor: { type: String, default: 'white' },
    buttonClass: { type: String, default: 'transparent' },
  },
  components: { UserAuthForm },
  data() {
    return {
      dialog: false,
      panels: [0, 1],
      resetCurrentProject: false,
      user: JSON.parse(JSON.stringify(this.inputUser)),
    };
  },
  methods: {
    updateUser() {
      if (this.$refs.userAuthForm.$refs.form.validate()) {
        if (this.resetCurrentProject) {
          this.user.currentProject = this.$store.getters[
            'projects/emptyProject'
          ]();
        }
        this.$emit('updateUser', this.user);
        this.close();
      }
    },
    close() {
      this.reset();
      this.dialog = false;
    },
    reset() {
      this.user = JSON.parse(JSON.stringify(this.inputUser));
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    openDialog(e) {
      e.cancelBubble = true;
      this.reset();
      this.dialog = true;
    },
  },
  computed: {
    formTitle() {
      return this.user.publicId === -1 ? 'New User' : 'Edit User';
    },
    formIcon() {
      return this.user.publicId === -1
        ? 'mdi-account-plus'
        : 'mdi-pencil';
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {},
};
</script>

<style scoped>
.switch-unit-button {
  pointer-events: auto;
}
</style>
