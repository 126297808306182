<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          v-for="user in users"
          :key="user.publicId"
        >
          <UserCard
            :user="user"
            :iconColor="iconColor"
            @deleteUser="deleteUser"
            @updateUser="updateUser"
            @activateUser="activateUser"
          />
        </v-col>
      </v-row>
      <UserPopup
        :inputUser="emptyUser()"
        :show="true"
        @updateUser="createUser"
        buttonClass="success"
      />
    </v-container>
  </div>
</template>

<script>
import UserCard from '@/components/auth/UserCard';
import UserPopup from '@/components/auth/UserPopup';
import { mapGetters } from 'vuex';
export default {
  name: 'Users',
  props: {},
  components: { UserCard, UserPopup },
  data() {
    return {};
  },
  methods: {
    deleteUser(publicId) {
      this.$store.dispatch('misc/setLoading', {
        status: true,
        text: 'Deleting User...',
      });
      this.$store
        .dispatch('deleteUser', publicId)
        .then(response => {
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .finally(() => {
          this.$store.dispatch('misc/setLoading', false);
        });
    },
    activateUser(publicId, activated) {
      this.$store
        .dispatch('activateUser', publicId, activated)
        .then(response => {
          this.users.find(
            user => user.publicId === publicId,
          ).activated = activated;
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    createUser(user) {
      this.$store
        .dispatch('createUser', user)
        .then(response => {
          this.users.push(response.data.user);
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    updateUser(updatedUser) {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch('updateUser', updatedUser)
        .then(response => {
          updatedUser = response.data.user;
          const index = this.users.findIndex(
            user => user.publicId === updatedUser.publicId,
          );
          if (index != -1) {
            this.users.splice(index, 1, updatedUser);
          }
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    getAllUsers() {
      this.$store
        .dispatch('getAllUsers')
        .then(response => {
          console.log('Users fetched from database.');
        })
        .catch(error => {
          console.log('Error getting all users');
        });
    },
  },
  computed: {
    ...mapGetters(['users', 'emptyUser', 'iconColor']),
  },
  mounted() {
    this.getAllUsers();
  },
};
</script>

<style></style>
